import Category from '../../components/Category/Category';
import Footer from '../../components/Footer/Footer';
import LuckPart from '../../components/LuckPart/LuckPart';
import Slider from '../../components/Slider/Slider';
import SeeBuy from '../../components/SeeBuy/SeeBuy';
import './Yalda.css';

function Yalda() {
    return (
        <div>
            <Slider />
            <Category />
            <LuckPart />
            <SeeBuy />
            <Footer />
        </div>
    );
}

export default Yalda;
